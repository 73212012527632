var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"permissions ma-1 elevation-1"},[_c('header',{staticClass:"permissions__header mb-4"},[_c('span',{staticClass:"permissions__heading"},[_vm._v(_vm._s(_vm.$t('headings.entities')))]),_c('span',{staticClass:"permissions__heading"},[_vm._v(_vm._s(_vm.$t('headings.actions')))]),_c('span',{staticClass:"permissions__heading"},[_vm._v(_vm._s(_vm.$t('headings.resources')))])]),_c('section',{staticClass:"permissions__tree"},[_c('div',[_c('v-checkbox',{staticClass:"check-all-checkbox",attrs:{"color":"secondary","ripple":false,"label":_vm.$t('allActions'),"disabled":!_vm.editable},on:{"change":_vm.onAllActionsChange},model:{value:(_vm.allActions),callback:function ($$v) {_vm.allActions=$$v},expression:"allActions"}}),_c('CyTooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"help-icon",attrs:{"color":"grey"}},on),[_vm._v(" help_outline ")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('allActionsTooltip'))+" ")])],1),_c('v-treeview',{attrs:{"hoverable":"","open-all":"","activable":"","selected-color":"secondary","item-key":"code","multiple-active":false,"items":_vm.entities,"active":[_vm.activeElementCode]},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"v-treeview-node__checkbox-wrapper",on:{"click":function($event){return _vm.openDetailsBox(item)}}},[_c('v-checkbox',{staticClass:"v-treeview-node__checkbox",attrs:{"color":"secondary","disabled":!_vm.editable,"indeterminate":_vm.isIndeterminate(item.actions),"input-value":_vm.areAllChecked(item.actions),"ripple":false},on:{"click":function($event){return _vm.toggleAllActions(item)}}})],1)]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('span',{class:['v-treeview-node__item'],on:{"click":function($event){return _vm.openDetailsBox(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"v-treeview-node__actions-wrapper",on:{"click":function($event){return _vm.openDetailsBox(item)}}},[_c('span',{staticClass:"actions"},[_vm._v(" "+_vm._s(_vm.displayEnabledActions(item.actions))+" ")]),(_vm.actionsWarning && item.code === _vm.activeElementCode)?_c('CyTag',{staticClass:"actions-warning",attrs:{"variant":"error","icon-before":"error"}},[_vm._v(" "+_vm._s(_vm.$t('untranslated.none'))+" ")]):_vm._e()],1),_c('span',{staticClass:"v-treeview-node__resources-selected-wrapper",on:{"click":function($event){return _vm.openDetailsBox(item)}}},[(!_vm._.isEmpty(item.resources) && !_vm._.isEqual(item.resources, []))?_c('CyTag',{staticClass:"resources-selected",attrs:{"variant":"primary"}},[_vm._v(" "+_vm._s(item.resources.length)+" "+_vm._s(_vm.$t('selected'))+" ")]):_vm._e()],1),_c('span',{staticClass:"v-treeview-node__arrow-wrapper",on:{"click":function($event){return _vm.openDetailsBox(item)}}},[_c('v-icon',[_vm._v("chevron_right")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }