<template>
  <CyDetails
    ref="cy-details"
    :item-id="item.canonical"
    :is-creation="$isCreationRoute"
    :on-save="onSave"
    :on-cancel="onCancel"
    :on-delete="() => $emit('on-delete')"
    :hide-delete="item.default"
    :can-cancel="canCancel"
    :can-save="!item.default && canSave"
    :loading="loading"
    :saving="saving"
    :deleting="deleting"
    :is-read-only="!hasUpdatePermissions || $static.cycloidRoles.includes(item.canonical)"
    :class="[{ 'role--is-default': item.default }]">
    <template slot="details_form">
      <slot name="alert"/>

      <CyAlert
        theme="error"
        :content="errors"/>

      <!-- Section: Form -->
      <section class="details mb-12">
        <h3 class="mb-6">
          {{ $t('Details') }}
        </h3>

        <v-text-field
          v-model="$v.formContent.name.$model"
          :label="$t('name')"
          :error-messages="nameErrors"
          :disabled="!canEditTextFields"
          required
          class="required-field"
          @blur="$v.formContent.name.$touch()"/>

        <v-textarea
          v-model="$v.formContent.description.$model"
          rows="4"
          :disabled="!canEditTextFields"
          :label="$t('forms.fieldDescription')"/>

        <slot name="additional-form-detail-fields"/>
      </section>
    </template>

    <template slot="details_formFullWidth">
      <div class="mt-6 mb-2">
        <h3 class="mb-2">
          {{ $t('headings.permissions') }}
        </h3>
        <p>
          {{ permissionsBoxSubHeading || $t('subheadings.permissions') }}
          <a
            :href="$docLinks.roles.policies"
            class="cy-link"
            target="_blank">
            {{ $t('learnMore') }}
          </a>
        </p>
      </div>
      <div class="d-flex">
        <!-- Section: Permissions box -->
        <section class="my-2 mr-6 permissions-section">
          <CyPermissionsBox
            ref="permissions-box"
            :active-element-code="selectedEntity.code"
            :actions-warning="show.actionsWarning"
            :entities="entities"
            :editable="isPermissionsBoxEditable"
            @open-details-failure="$refs['details-box'].shakeErrors()"
            @open-details="openDetailsBox($event)"
            @refresh-selected-entity="refreshSelectedEntity($event)"/>
        </section>

        <!-- Section: Details box -->
        <section class="details-section my-2">
          <CyDetailsBox
            v-if="show.detailsBox"
            ref="details-box"
            :key="selectedEntity.code"
            :editable="!item.default && !hasPermissionsDisabled && hasUpdatePermissions"
            :entity="selectedEntity"
            @close="closeDetailsBox"
            @change="updateSelectedEntity($event, entities, false)"
            @propagate="updateSelectedEntity($event, entities, true)"
            @show-actions-warning="$toggle.show.actionsWarning($event)"/>
          <CyAlert
            v-else
            :content="$t('infoNoEntitiesSelected')"/>
        </section>
      </div>

      <CyManualActionsBox
        v-if="canDisplayManualActionsBox"
        ref="manual-actions"
        v-model="manualActions"
        class="mt-6 mr-6"
        :disabled="hasPermissionsDisabled || !hasUpdatePermissions"
        @add:start="updateComboboxes"/>
    </template>
  </CyDetails>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import CyDetailsBox from '@/components/details-box.vue'
import CyDetails from '@/components/details.vue'
import CyManualActionsBox from '@/components/manual-actions-box.vue'
import CyPermissionsBox from '@/components/permissions-box.vue'
import { allValidCodes } from '@/components/policies-combobox.vue'
import { cycloidDefault } from '@/store/modules/organization'
import { anyChecksPass, checksPass } from '@/utils/helpers'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyFormsRole',
  components: {
    CyDetails,
    CyDetailsBox,
    CyManualActionsBox,
    CyPermissionsBox,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    saving: {
      type: Boolean,
      default: false,
    },
    deleting: {
      type: Boolean,
      default: false,
    },
    apiErrors: {
      type: Array,
      default: () => [],
    },
    permissionsBoxSubHeading: {
      type: String,
      default: undefined,
    },
    isDuplicating: {
      type: Boolean,
      default: false,
    },
    hasPermissionsDisabled: {
      type: Boolean,
      default: false,
    },
    hasOwnerChanged: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    formContent: {
      name: { required },
      description: {},
    },
    manualActions: {
      $each: {
        actions: {
          required,
          allValidCodes (values) {
            const codes = _.map(this.policies, 'code')
            return allValidCodes({ values, codes })
          },
        },
      },
    },
  },
  data: ({ item }) => ({
    formContent: {
      name: item?.name || '',
      description: item?.description || '',
    },
    entities: [],
    selectedEntity: {},
    manualActions: [],
    show: {
      detailsBox: false,
      actionsWarning: false,
    },
    loading: true,
  }),
  computed: {
    ...mapState('organization', {
      policiesErrors: (state) => state.errors.policies,
      policies: (state) => state.available.policies,
    }),
    ...mapGetters('user', ['isCycloidEmployee']),
    $static: () => ({
      cycloidRoles: cycloidDefault.roles,
    }),
    canDisplayManualActionsBox () {
      const { item, isCycloidEmployee, isDuplicating, manualActions } = this
      return manualActions?.length || checksPass({
        isNotItemDefault: !item.default,
        isCycloidEmployeeOrNotEmpty: isCycloidEmployee || !!manualActions.length,
        isCycloidEmployeeDuplicating: !isDuplicating || (isCycloidEmployee && isDuplicating),
      })
    },
    canEditTextFields () {
      const { item, isCycloidEmployee, isDuplicating, hasUpdatePermissions } = this

      return !item.default &&
        hasUpdatePermissions &&
        _.some([
          isCycloidEmployee,
          (!isCycloidEmployee && isDuplicating),
          (!isCycloidEmployee && !item.default),
        ])
    },
    errors () {
      const { policiesErrors = [], apiErrors = [] } = this
      return [...policiesErrors, ...apiErrors]
    },
    nameErrors () {
      const errors = []
      const { $dirty, required } = this.$v.formContent.name
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    canCancel () {
      return _.some([
        this.$hasDataChanged('formContent'),
        this.hasPermissionsChanged,
        this.hasOwnerChanged,
      ]) && !this.item.default
    },
    hasUpdatePermissions () {
      return {
        newRole: this.$cycloid.permissions.canDisplay('CreateRole'),
        role: this.$cycloid.permissions.canDisplay('UpdateRole', this.item.canonical),
        newApiKey: this.$cycloid.permissions.canDisplay('CreateAPIKey'),
        apiKey: this.$cycloid.permissions.canDisplay('UpdateAPIKey', this.item.canonical),
      }[this.$route.name]
    },
    hasPermissionsChanged () {
      return _.some([
        this.$hasDataChanged('entities'),
        this.$hasDataChanged('manualActions'),
      ]) && !this.hasPermissionsDisabled
    },
    hasRules () {
      return !_.isEmpty(this.generateRules())
    },
    hasChanges () {
      return this.$isCreationRoute
        ? this.$hasDataChanged('formContent') && this.hasPermissionsChanged
        : this.canCancel
    },
    isPermissionsBoxEditable () {
      const { item, hasPermissionsDisabled, hasUpdatePermissions } = this
      return !item.default && !hasPermissionsDisabled && hasUpdatePermissions
    },
    canSave () {
      const { isDuplicating, hasRules, hasChanges } = this
      return !this.$v.$invalid && hasRules && (isDuplicating || hasChanges)
    },
  },
  async mounted () {
    await this.FETCH_AVAILABLE({ keyPath: 'policies' })

    this.generateEntityTree(this.generateEntityList())
    this.fillManualActionsBox()

    this.$setOriginalData()

    this.$toggle.loading(false)
  },
  methods: {
    ...mapActions('organization', [
      'FETCH_AVAILABLE',
    ]),
    async closeDetailsBox () {
      this.$toggle.show.detailsBox(false)
      await this.$nextTick()
      this.$resetData('selectedEntity')
    },
    isPermissionsBoxRule (ruleFullCode, resources = []) {
      const { policies } = this
      const isOrgAdmin = ruleFullCode === 'organization:**'
      const isOrgMemberRead = ruleFullCode === '**:read'
      const isOrgMemberList = ruleFullCode === '**:list'
      const isInPoliciesList = !!_.find(policies, ({ code }) => ruleFullCode === code)
      const resourcesDoNotContainWildCard = !_.some(resources, (r) => r.includes('*'))

      return anyChecksPass({
        ...(resourcesDoNotContainWildCard ? { isInPoliciesList } : { resourcesDoNotContainWildCard }),
        isOrgAdmin,
        isOrgMemberRead,
        isOrgMemberList,
      })
    },
    fillManualActionsBox () {
      const rules = _.$get(this.item, 'rules', [])

      for (const { action, resources } of rules) {
        if (!this.isPermissionsBoxRule(action, resources)) {
          this.manualActions.push({ actions: [action], resources })
        }
      }
    },
    /**
     * With this method we will find only rules, in the retrieved role, having actions matching
     * what has been created with the permissions box. This means, only rules in the form of either
     * `organization:project:member:read` or `organization:project:member:list`
     * with the exception of the two Cycloid roles:
     *
     *  - Organization Member: 'organization:**'
     *  - Organization Admin: '**:read'
     *  - Organization Admin: '**:list'
     *
     * Anything else will be read as a manual action and added in the manual actions box.
     */
    findPermissionsBoxRule (code, action) {
      const fullCode = `${code}:${action}`
      const { item: { rules = [] } } = this

      return _.find(rules, ({ action: ruleFullCode }) => {
        const fullCodeMatch = ruleFullCode === fullCode
        const orgAdminMatch = ruleFullCode === 'organization:**'
        const orgMemberReadMatch = ruleFullCode === '**:read' ? action === 'read' : false
        const orgMemberListMatch = ruleFullCode === '**:list' ? action === 'list' : false

        return anyChecksPass({
          fullCodeMatch,
          orgAdminMatch,
          orgMemberReadMatch,
          orgMemberListMatch,
        })
      })
    },
    generateEntityList () {
      const { policies } = this
      const entityList = {}

      for (const { code: fullCode, description } of policies) {
        const code = fullCode.substring(0, fullCode.lastIndexOf(':'))
        const action = fullCode.substring(fullCode.lastIndexOf(':') + 1)
        const name = code.substring(code.lastIndexOf(':') < 0 ? 0 : code.lastIndexOf(':') + 1)

        if (!_.has(entityList, code)) {
          entityList[code] = {
            code,
            name,
            resources: this.getRuleResources(code),
            actions: {},
            children: [],
          }
        }
        entityList[code].actions[action] = {
          enabled: !!this.findPermissionsBoxRule(code, action),
          description,
        }
      }

      return entityList
    },
    generateEntityTree (entityList, currentEntity = null) {
      const { entities } = this

      if (_.isEmpty(entityList)) return

      if (!currentEntity) {
        const partition = _.partition(entityList, ({ code }) => code === 'organization')
        const children = partition[1]
        const baseEntity = { ...partition[0][0], children }

        entities.push(baseEntity)

        this.generateEntityTree(children, entities[0])
        return
      }

      const currentDepth = _.split(currentEntity.code, ':').length

      /*
       * This will create an array of two elements:
       *   - childrenPartition[0]: legit children of current entity
       *   - childrenPartition[1]: everything remaining (deeper depth, non-direct children, etc)
       */
      const childrenPartition = _.partition(entityList, ({ code: childCode }) => {
        const childDepth = _.split(childCode, ':').length
        return (
          childDepth === currentDepth + 1 &&
          childCode.indexOf(currentEntity.code) === 0
        )
      })

      currentEntity.children = childrenPartition[0]

      for (const child of currentEntity.children) {
        this.generateEntityTree(childrenPartition[1], child)
      }
    },
    getRuleResources (code) {
      const codeSections = code.split(':').map((section) => `${section}.*`).join('')
      const matchRegex = new RegExp(`${codeSections}.*`, 'gi')
      const resources = _.find(this.item.rules, (rule) => matchRegex.test(rule.action))?.resources || []
      const sectionName = _.last(code.split(':'))
      return resources.map((resource) => _.last(resource.split(`${sectionName}:`)))
    },
    openDetailsBox (selected) {
      this.selectedEntity = selected
      this.$toggle.show.detailsBox(true)
    },
    refreshSelectedEntity (activeElementCode, entities) {
      if (!entities) entities = this.entities

      for (const currentEntity of entities) {
        if (currentEntity.code === activeElementCode) {
          this.selectedEntity = { ...currentEntity, resources: [] }
          this.$nextTick(() => {
            this.$refs['details-box'].selectAllResources()
            this.$refs['details-box'].isAllResources = true
          })
          return
        }
        if (_.has(currentEntity, 'children')) this.refreshSelectedEntity(activeElementCode, currentEntity.children)
      }
    },
    extractRules (entities = []) {
      const rules = []

      for (const { actions, resources, code, children = [] } of entities) {
        const checkedActions = _.keys(_.pickBy(actions, ({ enabled }) => enabled))

        for (const action of checkedActions) {
          rules.push({
            action: `${code}:${action}`,
            effect: 'allow',
            resources: _.compact(resources.map((resource) => this.constructResourceFullPath(code, action, resource))),
          })
        }

        if (!_.isEmpty(children)) rules.push(...this.extractRules(children))
      }

      return rules
    },
    constructResourceFullPath (code, action, resource) {
      const entities = _.split(code, ':')
      let codeWithCanonicals = ''

      // BE sends us an error if we try specifying resources for create actions
      if (action === 'create') return

      for (const entity of entities) {
        if (entity === 'organization') codeWithCanonicals += `${entity}:${this.orgCanonical}:`
        else codeWithCanonicals += `${entity}:*:`
      }

      return `${_.trimEnd(codeWithCanonicals, '*:')}:${String(resource)}`
    },
    generateRules () {
      const { manualActions, entities } = this
      const everythingChecked = _.$get(this.$refs['permissions-box'], 'allActions', false)
      let rules = []

      if (everythingChecked) {
        rules.push({
          action: `organization:**`,
          effect: 'allow',
          resources: [],
        })
        // avoid returning here when we'll implement the `disallow` effect
        return rules
      }

      rules = _.cloneDeep(this.extractRules(entities))

      for (const { actions = [], resources = [] } of manualActions) {
        for (const action of actions) {
          rules.push({
            action,
            effect: 'allow',
            resources,
          })
        }
      }

      return rules
    },
    async onSave () {
      this.updateComboboxes()
      this.$emit('on-save', { ...this.formContent, rules: this.generateRules() })
    },
    onCancel () {
      this.$emit('on-cancel')
      this.$resetData('formContent')
      this.$resetData('manualActions')
      this.closeDetailsBox()
      this.$resetData('entities')
    },
    updateSelectedEntity (inputEntity, targetEntities, propagate) {
      if (targetEntities === this.entities) this.selectedEntity = inputEntity

      _.forEach(targetEntities, (entity) => {
        const shouldPropagate = propagate && _.startsWith(entity.code, inputEntity.code)

        if (entity.code === inputEntity.code || shouldPropagate) {
          if (entity.code === inputEntity.code) {
            entity.children = inputEntity.children
            entity.resources = inputEntity.resources
          }

          // to ensure we only pick actions an entity has when propagating
          for (const action in entity.actions) {
            if (inputEntity.actions[action]) entity.actions[action].enabled = inputEntity.actions[action].enabled

            // remove any resource for children, to avoid having unselected actions but selected resources
            if (propagate && entity.code !== inputEntity.code) entity.resources = []
          }

          if (!propagate || _.isEmpty(inputEntity.children)) return
        }

        if (_.has(entity, 'children')) {
          this.updateSelectedEntity(inputEntity, entity.children, propagate)
        }
      })
    },
    updateComboboxes () {
      const manualActions = this.$refs['manual-actions']?.$refs.action
      const comboboxes = _.reduce(manualActions, (result, manualAction) => {
        const comboboxesSoFar = [...result]
        const actionsCombobox = manualAction?.$refs.actions?.$refs.combobox?.$refs.combobox
        const resourcesCombobox = manualAction?.$refs.resources?.$refs.combobox

        if (actionsCombobox) {
          comboboxesSoFar.push(actionsCombobox)
        }
        if (resourcesCombobox) {
          comboboxesSoFar.push(resourcesCombobox)
        }
        return comboboxesSoFar
      }, [])

      for (const combobox of comboboxes) {
        combobox.updateSelf()
      }
    },
  },
  i18n: {
    messages: {
      en: {
        headings: {
          permissions: 'Permissions',
        },
        infoNoEntitiesSelected: 'Click on an entity to see its details',
        subheadings: {
          manualActions: 'Manual actions allow advanced users to use globbing to target multiple actions and/or resources.',
          permissions: 'Define what this @:role can do. For each entity type, control access to specific actions and resources.',
        },
      },
      es: {
        headings: {
          permissions: 'Permisos',
        },
        infoNoEntitiesSelected: 'Haga clic en una entidad para ver sus detalles',
        subheadings: {
          manualActions: 'Las acciones manuales permiten a los usuarios avanzados utilizar la función global para apuntar a múltiples acciones y/o recursos.',
          permissions: 'Defina lo que este @:role puede hacer. Para cada tipo de entidad, controle el acceso a acciones y recursos específicos.',
        },
      },
      fr: {
        headings: {
          permissions: 'Autorisations',
        },
        infoNoEntitiesSelected: 'Cliquez sur une entité pour voir ses détails',
        subheadings: {
          manualActions: `Les actions manuelles permettent aux utilisateurs avancés d'utiliser la fonction globale pour cibler de multiples actions et/ou des ressources.`,
          permissions: `Définissez ce que ce @:role peut faire. Pour chaque type d'entité, contrôlez l'accès à des actions et des ressources spécifiques.`,
        },
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.details {
  min-width: 450px;
}

.permissions-section {
  width: 100%;
}

.details-section {
  width: 100%;
  max-width: 384px;
}

.manual-actions-section {
  width: 100%;
  max-width: 896px;
}
</style>
