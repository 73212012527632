<template>
  <CyInputsCombobox
    ref="combobox"
    v-model="valueProxy"
    v-bind="$attrs"
    :items="suggestions"
    :search-input.sync="searchInput"
    :error-messages="errors"
    no-filter
    @change="(items) => $emit('change', items)"
    @input="(items) => $emit('input', items)"
    @focus="$emit('focus')"
    @blur="$emit('blur')">
    <template #item="{ item }">
      <v-list-item
        v-if="item"
        class="combobox__item"
        :disabled="!!(item.cyHeader || item.cyDivider)"
        @click.prevent.stop="onSelect(item)">
        <v-list-item-content
          v-if="item.cyAction || item.cyEntity"
          class="combobox__content">
          {{ item.cyAction || item.cyEntity }}
        </v-list-item-content>
        <v-subheader
          v-if="item.cyHeader"
          class="combobox__subheader">
          {{ item.cyHeader }}
        </v-subheader>
        <v-divider
          v-if="item.cyDivider"
          class="combobox__divider"/>
      </v-list-item>
    </template>
  </CyInputsCombobox>
</template>

<script>
import { mapState } from 'vuex'
import CyInputsCombobox from '@/components/inputs/combobox.vue'

export const allValidCodes = ({ values, codes }) => {
  return _.every(values, (value) => {
    const asterisksRegex = value
    // regex explained (hint: <asterisk> in the end become just a *)
      ?.replace(/\*\*.*/g, '.<asterisk>') // we allow everything from ** on
      ?.replace(/\*/g, '(?!.<asterisk>:).<asterisk>') // we look only for actions (must be no : between match and end of the word)
      ?.replace(/<asterisk>/g, '*') + '$' // <asterisk> becomes * and we match until end of the string
    return _.some(codes, (code) => code.match(asterisksRegex)) && value.startsWith('organization:') && !value.endsWith(':')
  })
}

export default {
  name: 'CyPoliciesCombobox',
  components: {
    CyInputsCombobox,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    errorMessages: {
      type: Array,
      default: () => ([]),
    },
  },
  validations: {
    valueProxy: {
      allValidCodes (values) {
        const { codes } = this
        return allValidCodes({ values, codes })
      },
    },
  },
  data: () => ({
    searchInput: null,
  }),
  computed: {
    ...mapState('organization', {
      policies: (state) => state.available.policies,
    }),
    valueProxy: {
      set (value) { this.$emit('input', value) },
      get () { return this.value },
    },
    codes () {
      return _.map(this.policies, 'code')
    },
    errors () {
      const errors = [...this.errorMessages]
      const { allValidCodes } = this.$v.valueProxy
      if (!allValidCodes) errors.push(this.$t('doesNotContainInvalidPaths'))
      return errors
    },
    filteredCodes () {
      return _.difference(this.codes, this.valueProxy)
    },
    filteredTree () {
      const tree = {}
      this.filteredCodes
        .forEach((code) => {
          const [action, ...entities] = code.split(':').reverse()
          const path = `entities.${entities.reverse().join('.entities.')}.actions.${action}`
          _.set(tree, path, code)
        })
      return tree
    },
    suggestions () {
      let head = this.filteredTree
      this.searchInput
        ?.split(':')
        ?.filter((entity) => !_.isEmpty(entity))
        ?.forEach((entity) => { head = head?.entities?.[entity] || head })
      const startsWith = this.searchInput?.split(':')?.pop() || ''
      const entities = _.keys(head?.entities || {}).sort().filter((entity) => entity.startsWith(startsWith))
      const actions = _.keys(head?.actions || {}).sort().filter((action) => action.startsWith(startsWith))
      return [
        ...(!_.isEmpty(actions) ? [{ cyHeader: this.$t('actions') }, ...actions.map((action) => ({ cyAction: action }))] : []),
        ...((!_.isEmpty(actions) && !_.isEmpty(entities)) ? [{ cyDivider: true }] : []),
        ...(!_.isEmpty(entities) ? [{ cyHeader: this.$t('entities') }, ...entities.map((entity) => ({ cyEntity: entity }))] : []),
      ]
    },
  },
  methods: {
    onSelect (item) {
      if (item.cyHeader || item.cyDivider) return
      this.searchInput = this.searchInput?.substring(0, 1 + this.searchInput?.lastIndexOf(':')) || ''
      this.searchInput += item.cyAction || item.cyEntity
      if (item.cyEntity) {
        this.searchInput += ':'
      } else {
        this.valueProxy = [...this.valueProxy, this.searchInput]
        this.searchInput = null
      }
      this.$el.querySelector('input')?.focus()
    },
  },
  i18n: {
    messages: {
      en: {
        actions: 'Actions',
        doesNotContainInvalidPaths: `This field doesn't contain valid codes`,
        entities: 'Entities',
      },
      es: {
        actions: 'Acciones',
        doesNotContainInvalidPaths: 'Este campo no contiene códigos válidos.',
        entities: 'Entidades',
      },
      fr: {
        actions: 'Actions',
        doesNotContainInvalidPaths: 'Ce champ ne contient pas de codes valides',
        entities: 'Entités',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .combobox {
    &__item {
      min-height: 0;
      padding: 0;
    }

    &__content {
      padding: 4px 16px;
    }

    &__subheader {
      height: 32px;
      min-height: 0;
      margin: 0;
      padding: 16px;
      font-size: $font-size-sm;
      font-weight: $font-weight-bolder;
      line-height: $line-height-heading;
      text-transform: uppercase;
    }

    &__divider {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
</style>
